exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-examples-js": () => import("./../../../src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("./../../../src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-appointment-page-js": () => import("./../../../src/templates/appointment-page.js" /* webpackChunkName: "component---src-templates-appointment-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-holidays-entry-js": () => import("./../../../src/templates/holidays-entry.js" /* webpackChunkName: "component---src-templates-holidays-entry-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-images-about-js": () => import("./../../../src/templates/images-about.js" /* webpackChunkName: "component---src-templates-images-about-js" */),
  "component---src-templates-images-customers-js": () => import("./../../../src/templates/images-customers.js" /* webpackChunkName: "component---src-templates-images-customers-js" */),
  "component---src-templates-images-gallery-js": () => import("./../../../src/templates/images-gallery.js" /* webpackChunkName: "component---src-templates-images-gallery-js" */),
  "component---src-templates-images-store-js": () => import("./../../../src/templates/images-store.js" /* webpackChunkName: "component---src-templates-images-store-js" */),
  "component---src-templates-info-page-js": () => import("./../../../src/templates/info-page.js" /* webpackChunkName: "component---src-templates-info-page-js" */),
  "component---src-templates-kunsthaar-pflegeanleitung-page-js": () => import("./../../../src/templates/kunsthaarPflegeanleitung-page.js" /* webpackChunkName: "component---src-templates-kunsthaar-pflegeanleitung-page-js" */),
  "component---src-templates-opening-hours-entry-js": () => import("./../../../src/templates/openingHours-entry.js" /* webpackChunkName: "component---src-templates-opening-hours-entry-js" */),
  "component---src-templates-prices-page-js": () => import("./../../../src/templates/prices-page.js" /* webpackChunkName: "component---src-templates-prices-page-js" */),
  "component---src-templates-product-post-js": () => import("./../../../src/templates/product-post.js" /* webpackChunkName: "component---src-templates-product-post-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-testimonial-post-js": () => import("./../../../src/templates/testimonial-post.js" /* webpackChunkName: "component---src-templates-testimonial-post-js" */)
}

