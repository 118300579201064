import React from 'react'

const SvgIconfinderFacebookCircleColor107175 = props => (
  <svg height={67} width={67} viewBox="0 0 67 67" {...props}>
    <path
      d="M29.765 50.32h6.744V33.998h4.499l.596-5.624h-5.095l.007-2.816c0-1.466.14-2.253 2.244-2.253h2.812V17.68h-4.5c-5.405 0-7.307 2.729-7.307 7.317v3.377h-3.369v5.625h3.369V50.32zM34 64C17.432 64 4 50.568 4 34 4 17.431 17.432 4 34 4s30 13.431 30 30c0 16.568-13.432 30-30 30z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#3a589b"
    />
  </svg>
)

export default SvgIconfinderFacebookCircleColor107175
